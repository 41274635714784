import * as React from "react";
import { Carousel } from "react-bootstrap";
import qh from "../../images/logos/QD-Logo-H.png";
import euromere from "../../images/logos/logo-euromere.png";
import award from "../../images/logos/logo-award.png";
import gz from "../../images/logos/gz-logo.png";
import diab from "../../images/logos/DIAB_logo_new1.png";
import thermhex from "../../images/logos/thermhex.png";

const PartnerCarousel = (props) => (
  <Carousel
    fade
    interval={2000}
    prevLabel={null}
    nextLabel={null}
    prevIcon={
      <div className="icon-wrapper-prev">
        <span aria-hidden="true" className="carousel-control-prev-icon" />
      </div>
    }
    nextIcon={
      <div className="icon-wrapper-next">
        <span aria-hidden="true" className="carousel-control-next-icon" />
      </div>
    }
  >
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={qh}
          alt="First slide"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={euromere}
          alt="Second slide"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={award}
          alt="Third slide"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={gz}
          alt="Third slide"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={diab}
          alt="Third slide"
        />
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="partner-container">
        <img
          style={{ maxWidth: "250px" }}
          className="img-fluid w-100"
          src={thermhex}
          alt="Third slide"
        />
      </div>
    </Carousel.Item>
  </Carousel>
);
export default PartnerCarousel;

import * as React from "react";
import { Link } from "gatsby";
import { Carousel } from "react-bootstrap";
import bateau from "../../images/homepage/bateau.jpg";
import nautisme from "../../images/homepage/nautisme.jpg";
import car from "../../images/homepage/car.jpg";
import loisir from "../../images/homepage/loisir.jpg";
import sanitaire from "../../images/homepage/sanitaire.jpg";

const HomeCarousel = (props) => (
  <div className="page-carousel">
    <Carousel
      fade
      interval={5000}
      prevLabel={null}
      nextLabel={null}
      prevIcon={
        <div className="icon-wrapper-prev">
          <span aria-hidden="true" className="carousel-control-prev-icon" />
        </div>
      }
      nextIcon={
        <div className="icon-wrapper-next">
          <span aria-hidden="true" className="carousel-control-next-icon" />
        </div>
      }
    >
      <Carousel.Item>
        <img className="img-fluid w-100" src={bateau} alt="First slide" />
        <div className="carousel-filter"></div>
        <Carousel.Caption>
          <h1>
            <b>Carthage Composites Distribution</b>
          </h1>
          <h3>
            Carthage Composites est une société specialisée dans la distribution
            de matériaux composites avec une panoplie de produits innovants
            adaptés à vos besoin Nous vous proposons une gamme complète de
            gels-coat, colles, résines, silicone, latex, Fibre de verre et
            autres ...
          </h3>

          <br />
          <Link className="btn btn-outline-neutral btn-round" to="/produits">
            Découvrez nos produits
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="img-fluid w-100" src={nautisme} alt="Second slide" />
        <div className="carousel-filter"></div>

        <Carousel.Caption>
          <h1>
            <b>Nautisme</b>
          </h1>
          <h3>
            Passionnés de pêche, de sports nautiques ou bien désireux de
            concevoir/réparer votre bateau de croisière, venez découvrir nos
            produits.
          </h3>
          <br />
          <Link className="btn btn-outline-neutral btn-round" to="/produits">
            Découvrez nos produits
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="img-fluid w-100" src={car} alt="Third slide" />
        <div className="carousel-filter"></div>

        <Carousel.Caption>
          <h1>
            <b>Transport</b>
          </h1>
          <h3>
            Passionnés ou proffessionnels du domaine automobile, nous vous
            proposons, en plus de nos produits, des conseils et astuces pour
            vous guider dans la conception de vos projets.
          </h3>
          <br />
          <Link className="btn btn-outline-neutral btn-round" to="/produits">
            Découvrez nos produits
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="img-fluid w-100" src={loisir} alt="Third slide" />
        <div className="carousel-filter"></div>

        <Carousel.Caption>
          <h1>
            <b>Art et loisir</b>
          </h1>
          <h3>
            Mobilier, décoration, piscine, sports nautiques ... Nous vous
            accompagnons lors de la réalisation de tous vos articles
          </h3>
          <br />
          <Link className="btn btn-outline-neutral btn-round" to="/produits">
            Découvrez nos produits
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="img-fluid w-100" src={sanitaire} alt="Third slide" />
        <div className="carousel-filter"></div>

        <Carousel.Caption>
          <h1>
            <b>Sanitaire</b>
          </h1>
          <h3>
            Vous trouverez chez nous les produits nécéssaires à la réalisation
            de vos articles.
          </h3>
          <br />
          <Link className="btn btn-outline-neutral btn-round" to="/produits">
            Découvrez nos produits
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </div>
);
export default HomeCarousel;
